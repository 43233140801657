import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Overview`}</h1>
    <p>{`The Content Hub allows you to integrate third-party systems, such as an RSS feed or YouTube, into Studio. You can browse
the content in the Library and create CoreMedia content items from third-party content shown in the Library.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAACToAAAk6AGCYwUcAAACnElEQVQoz4WRS28URxSF+xexZROJoEB+B38AEikbS5FYhEWyAicoluNEeYgoLxAYCccYbCKCiDWRmRg0Nj097enp96N6prun3Y8Zz/hDXcjOIotc6eicc1W6t+qU8s7yJmdvPuT88iMufr0l8f43Tzj35TrvLj087V346jEXVjYlv9do6f/tXVzZ5MyNNZQPv73PTy2V3Z6B5gk6A5d9y6fV6bKrm9KrToAhUvpRgiGSt/qEo0TCFAm/7mgo3c4r5nXJOMvIxxnjLCVNErI05TAfkyYj2S8Oc4lGp2kiuTmT5zlVWRJFETvbz1H0fh/TssiyjOFoSJal2K7Hp6stPrnbYvH3Np9vvGRxfZfr67tcu9vio1tPufPXPrEICSJBGAni0QjT8VDCMMT3faqqoigKyUEY0u6o6LbPvj6go/Ulq30bzfTo6APCJKer6TzbbtF+tUd/YJLlhygijnFcl7qeMD8+pqk4jtG6KkMR4dgWrm3hWCa+5yLCANcyKeuaja2nLHxwhasLC/xye5UgHqFwfITwHdI4Yhj6HE9KJlWB2u0SBAGWZUk0sbiui+f72LZFVtS87Lzmt8WPuXb5Eo/vr+LHKcp2P2aj3eNBa4+1v1XW2gf8o7sc6D050DAMTNPEtm2JJp7Gj4uKnRcv+HHpM1aWl7h97wF5UaL8sG3w/TOVn5+rfPfna77YUvljz8Yy+qc3PBnmeR5vM/cYpRllVeGHgpOqJlOURhRJjPBsktCD+pBZXTKZTpnP58xms1NIP59LLuuJHG4ODIQQlGVJWU9RpkdHuJ6P7TiMkpSirKjqmv+rydGMSAh6vR7GYCCXTGZzFMsaoOs9dF3HdR35nOZpzdbmt/8LwTCOCaNIxqFpGgcHuuwHUcQbNs9VPfnT4SsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/03c200cce81f9e7e92fe75454ccb2030/e93cc/library-content-hub.webp 300w", "/static/03c200cce81f9e7e92fe75454ccb2030/b0544/library-content-hub.webp 600w", "/static/03c200cce81f9e7e92fe75454ccb2030/68fc1/library-content-hub.webp 1200w", "/static/03c200cce81f9e7e92fe75454ccb2030/a2303/library-content-hub.webp 1800w", "/static/03c200cce81f9e7e92fe75454ccb2030/4293a/library-content-hub.webp 2400w", "/static/03c200cce81f9e7e92fe75454ccb2030/ea667/library-content-hub.webp 2443w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/03c200cce81f9e7e92fe75454ccb2030/eed55/library-content-hub.png 300w", "/static/03c200cce81f9e7e92fe75454ccb2030/7491f/library-content-hub.png 600w", "/static/03c200cce81f9e7e92fe75454ccb2030/8537d/library-content-hub.png 1200w", "/static/03c200cce81f9e7e92fe75454ccb2030/d2cc9/library-content-hub.png 1800w", "/static/03c200cce81f9e7e92fe75454ccb2030/00711/library-content-hub.png 2400w", "/static/03c200cce81f9e7e92fe75454ccb2030/1dac6/library-content-hub.png 2443w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/03c200cce81f9e7e92fe75454ccb2030/8537d/library-content-hub.png",
            "alt": "library content hub",
            "title": "library content hub",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p><em parentName="p">{`YouTube integration via the Content Hub in the Library`}</em></p>
    <h2>{`Use Cases`}</h2>
    <ul>
      <li parentName="ul">{`Integrate various external systems into the Studio Library.`}</li>
    </ul>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to write your own Content Hub adapter to integrate content of external systems in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/studio-developer-en/content/Content_Hub.html"
        }}>{`Studio Developer Manual`}</a></li>
      <li parentName="ul">{`Browse the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/artifacts/2201.2/javadoc/studio-server/index.html"
        }}>{`Content Hub API`}</a></li>
      <li parentName="ul">{`Learn how to use the Content Hub in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/studio-user-en/content/contenthub.html"
        }}>{`Studio User Manual`}</a></li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/content-hub-adapter-youtube"
        }}>{`Content Hub Adapter for YouTube`}</a>{` that
demonstrates the integration of YouTube content into the CoreMedia system`}</li>
      <li parentName="ul">{`As an example, study the `}<a parentName="li" {...{
          "href": "https://github.com/CoreMedia/content-hub-adapter-rss"
        }}>{`Content Hub Adapter for RSS`}</a>{` that demonstrates the integration of
RSS feeds into the CoreMedia system`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      